import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import { useTranslations } from '../hooks/use-translations'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const ErpSoftwareSystemPage: React.FC = () => {
  const { getPath } = useTranslations()

  return (
    <>
      <SEO
        title="ERP software in the UAE | SIGNAX"
        description="SIGNAX provides time-tested and customer-proven enterprise resource planning software in Dubai, UAE.| Best ERP software system from Signax"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          All-in-one ERP software for construction in Dubai
        </Typography>
        <Typography variant="h2" color="blue">
          What is ERP
        </Typography>
        <Typography variant="body1">
          Enterprise resource planning (ERP) is the unified management of most
          important business processes on base of software and technology part.
          Now it is usually operates in real time. People often think of ERP as
          a category of business management software — typically, a suite of
          integrated applications. The company uses it to collect, store, manage
          and work with digital data for most of business needs. There are 2
          types of ERP: local based or cloud-based. Cloud-based applications
          became most popular in previous 5-7 years as with Internet access is
          available almost anywhere nowadays.
        </Typography>
        <Typography variant="body1">
          ERP gives a consistent and updated view of core business processes
          using common databases operated by a database management system. The
          main goal of ERP – tracking of resources: cash, raw materials;
          production capacity and the status of orders, purchase orders, and
          payroll.
        </Typography>
        <Typography variant="body1">
          The software that acts inside the system uses data from various
          departments (design, manufacturing, procurement, production, sales,
          accounting, etc.). ERP provides data flow between all business
          functions and manages connections to outside users. The integration
          between varied organizational systems, error-free transactions and
          effective production are made with help of ERP.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/erp-software-system/image-1.png"
            alt="ERP system and its components"
            title="ERP system and its components"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Pic 1. ERP system and its components
          </Typography>
        </div>
        <Typography variant="body1">
          The following features are usually included in ERP today:
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>An integrated system;</li>
          <li>Operates online;</li>
          <li>A common database that supports all the modules;</li>
          <li>A unified interface across modules;</li>
          <li>
            IT department is involved in implementation process as digital data
            is used by everybody in company;
          </li>
          <li>
            There is a list of options for ERP architecture: on-premis , cloud
            hosted, or SaaS.
          </li>
        </ol>
        <Typography variant="h2" color="blue">
          ERP software in construction
        </Typography>
        <Typography variant="body1">
          Enterprise Resource Planning (ERP) in construction designed
          specifically for construction companies. It should manage the core
          business processes of the companies in industry, including project
          management, project analytics, project budgeting, general and
          subcontractor management, resource management, asset management,
          payroll, and more.
        </Typography>
        <Typography variant="body1">
          For many years, the big dream was to find construction business
          software, which will represent the functions of ERP in the best way,
          but most systems do not cover the core of a construction company’s
          business process needs. Moreover, don’t allows to make management
          data-driven. For example, the top-tier ERPs (like Microsoft Dynamics
          for Construction) simply do not offer a viable solution for
          construction companies.
        </Typography>
        <Typography variant="body1">
          We can admit that Building Information Modelling (BIM) technology
          becomes the center of information source in construction projects
          today. It is so, because BIM operates the actual digital prototype of
          real facility at any stage. Each item of BIM model has specific
          parameters (dimensions, material, classification, price, vendor name
          and more). It allows making accurate calculations, bills of materials,
          schedules of works – all the data in construction becomes precise up
          to date. You can find more of «What is BIM»{' '}
          <Link
            to={getPath('/building-information-modeling/')}
            className="has-text-primary"
          >
            here.
          </Link>
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/erp-software-system/image-2.png"
            alt="BIM lifecycle"
            title="BIM lifecycle"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Pic 2. BIM lifecycle{' '}
          </Typography>
        </div>
        <Typography variant="body1">
          ERP in construction must use the power of BIM nowadays! There are some
          examples when the integration between CAD/BIM software and top-tier
          ERP exist. At the same time, it is much more effective to establish
          and implement ERP on base of construction common data environment
          (CDE) and use special functionality that supports BIM or deep
          integration with BIM just «in box».
        </Typography>
        <Typography variant="h2" color="blue">
          SIGNAX as a core of corporates ERP
        </Typography>
        <Typography variant="body1">
          SIGNAX is a set of digital products that improves project management
          in construction. It helps construction companies and their managers to
          automate business functions. Let’s have a look how SIGNAX cover all
          main ERP functionality:
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>
            An integrated system. SIGNAX works on base of Autodesk Docs CDE and
            is ready to connect to all popular CAD/BIM formats including DWG
            (AutoCAD), RVT (Revit) and IFC (universal). It also can link with
            Business Intelligence (BI) systems, Data Bases, Microsoft Excel and
            many other sources;
          </li>
          <li>
            Operates in real time. SIGNAX works in browser and it available at
            any device. All the data is up to data and has both directions link
            to BIM model with auto update mode;
          </li>
          <li>
            A common database supports all the modules. You don’t need to input
            data twice. It is everywhere thanks to cloud technology. There are
            also pre-made templates of valuable reports right inside SIGNAX.
            Just set smart data cards® and get report with your most needed
            construction data;
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                className={`${s.imageBlock} mb-0`}
                src="../assets/images/solutions/erp-software-system/image-3.png"
                alt="Smart data cards® inside SIGNAX"
                title="Smart data cards® inside SIGNAX"
                placeholder="blurred"
              />
              <Typography variant="body2" color="gray">
                Pic 3. Smart data cards® inside SIGNAX
              </Typography>
            </div>
          </li>
          <li>
            A unified interface across modules. SIGNAX consists of 3 modules:
            Dashboard, Inspection and Tools. They form the unified informational
            area and deeply interact with each other. Make your correct and rich
            construction BIM model in Tools. Generate and analyze 360° panoramic
            photos from site in Inspection. Use digital data for full
            understanding of actual situation and manage the project effectively
            with SIGNAX Dashboard;
          </li>
          <li>
            SIGNAX digital data is used by every department in company as it is
            precise and pretends to be the only source of truth. Of course, the
            system should have its administrators and the team responsible for
            data integration. IT or BIM department can play this role and help
            users in business processes settings and support;
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../assets/images/solutions/erp-software-system/image-4.png"
                alt="Unified interface for users and support"
                title="Unified interface for users and support"
                placeholder="blurred"
              />
              <Typography variant="body2" color="gray">
                Pic 4. Unified interface for users and support
              </Typography>
            </div>
          </li>
          <li>
            SIGNAX is a SaaS solution and has all advantages of cloud: access
            from every devise, at every time and from everywhere. At the same
            time, you can deploy it on premise or using public cloud. It depends
            on your corporate information security (IS) guidelines.
          </li>
        </ol>
        <Typography variant="body1">
          Signax provides time-tested and customer-proven Enterprise resource
          planning software in Dubai, UAE.
        </Typography>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          If the construction company wants to change and use digital data to
          improve its effectiveness, it should use ERP system as the center of
          integrated management for main business processes. Construction
          industry can’t use top-tier ERPs as they are not ready enough to
          support its specific features, ensure noticeable progress and fast
          implementation. Best in class ERP software system from SIGNAX can
          cover all these needs and help customer increase digital
          transformation effect in practice.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/erp-software-system/image-5.png"
            alt="Effective construction site processes are available right now"
            title="Effective construction site processes are available right now"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Pic 4. Unified interface for users and support
          </Typography>
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default ErpSoftwareSystemPage
